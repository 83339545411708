import React, { useContext, useRef, useState, useEffect, forwardRef } from 'react';
import { AppContext } from '../../../context';
import { ADD_USER_APPROVAL, ADD_USER_OPTIN } from '../../../utilities/mutations';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Loading from '../../Shared/Loading';
import styles from './ApprovalUown.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Radium from 'radium';
import { useMediaQuery } from 'react-responsive';
import LogRocket from 'logrocket';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialog = ({ open, handleConfirm, handleCancel}) => {
  
    const handleCloseAttempt = () => {
      console.log('clicked outside alert modal');
    };
  
    return (        
        <Dialog
            open={open}
            onClose={handleCloseAttempt}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            TransitionComponent={Transition}
        >
            <DialogTitle id='alert-dialog-title'>
                {'Close the application?'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                You are about to leave the pre-approval application. Are you sure you want to exit? 
                You can always come back and complete it later.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}>Leave</Button>
                <Button onClick={handleCancel}>Continue</Button>
            </DialogActions>
        </Dialog>
    );
}
const ApprovalUown = () => {
    let history = useHistory();
    const [ openApplication, setOpenApplication ] = useState(true);
    const [ openCloseAlert, setCloseAlert ] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [ loading, setLoading ] = useState(true);
    const sentToMongo = useRef(false);
    const sentToGoogle = useRef(false);
    const iframeUrlBase = 'https://uown-stage1-dd.rightandabove.com';
    // const iframeUrlBase = 'https://uown-approval-iframe.buyontrust.com';
    const { trackingState, dispatchApp, GtagEvent } = useContext(AppContext);
    const { gclid, pid, oid, eid, sid } = trackingState;
    const fullIframeUrl = `${iframeUrlBase}?gclid=${gclid}&pid=${pid}&oid=${oid}&sid=${sid}&eid=${eid}`;

    const [addUserApproval] = useMutation(ADD_USER_APPROVAL, {
		onCompleted: data => {console.log(data.addUserApproval.message)},
		onError: error => {console.error(error)}
	});

    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);

    const iframeLoaded = () => {
        console.log('iframe loaded');
        setLoading(false);
    };

    useEffect(() => {
        if(!sentToGoogle.current) {
            GtagEvent('Approvals', 'Form Sent', 'Contact Info', 'Opening Application Modal');
            sentToGoogle.current = true;
        };
        // eslint-disable-next-line
    },[]);

    const handlePostResult = () => {
        console.log('Sent Optin to Mongo: SUCCESS');
    };

    const [addUserOptin] = useMutation(ADD_USER_OPTIN, { 
        onCompleted: handlePostResult, 
        onError: (err) => console.error('Saving User: FAILED', err) 
    });

    const handleApplicationFinish = (dataObj) => {
		const payload = {			
			first: dataObj?.firstName,
            last: dataObj?.lastName,
            email: dataObj?.email,
            phone: dataObj?.phone,
			clickId: Number(trackingState.hsid)
		};
		addUserOptin({ variables: { visitor: payload } });
        if(!sentToMongo.current) {
            const approvalBody = { 
                clickId: Number(trackingState.hsid),
                phone: payload?.phone,
                email: payload?.email,
                approvalData: {
                    uown_app_id: String(dataObj.applicationId),
                    application_status: dataObj.applicationStatus,
                    approval_amount: Number(dataObj.approvalAmount) || 0,
                }
            };
            addUserApproval({ variables: approvalBody });
            sentToMongo.current = true;
        };
        const userData = Object.assign({}, payload, {
            uown_app_id: String(dataObj.applicationId),
            application_status: dataObj.applicationStatus,
            approval_amount: Number(dataObj.approvalAmount) || 0,
        });
        dispatchApp({type:'USER_APPROVAL', payload: userData});
        LogRocket.identify(trackingState.hsid, {
            email: dataObj?.email,
            phone: dataObj?.phone, 
        });
        switch(dataObj.applicationStatus) {
            case 'approved':
                setLoading(false);
                history.push('/Congratulations');
                return;
            case 'not_approved':
                setLoading(false);
                history.push('/Declination');
                return;
            case 'abandoned':
                setLoading(false);
                history.push('/');
                return;
            default: {
                setLoading(false);
            }
        };
    };

    window.onmessage = (event) => {
        if(event.origin === iframeUrlBase && event.data?.type !== 'TikTokPixelSPAMonitor') {
            console.log('Message from buyontrust iframe:', event.data);
        };
        if(event.origin === iframeUrlBase && event.data?.label === 'uown-finished') {
            setLoading(true);
            console.log('Message with label - uown-finished:', event.data);
            const resData = event.data.info;
            handleApplicationFinish(resData);
        };
	};

    const handleCloseApplication = () => {
        setCloseAlert(true);
    };
    const handleConfirmCloseApplication = () => {
        setOpenApplication(false);
        setCloseAlert(false);
        history.push('/');
    };
    const handleCancelCloseApplication = () => {
        setOpenApplication(true);
        setCloseAlert(false);
    };

    const iframeStyle = Object.assign({},
        styles.iframeStyle,
        loading && styles.hideIframe
    );
    const iframeOverrideStyle = Object.assign({},
        styles.loadingContainer,
        isMobile && styles.mobileLoadingContainer
    );

    return(
        <div style={styles.container}>
            {openCloseAlert && <AlertDialog open={openCloseAlert} handleConfirm={handleConfirmCloseApplication} handleCancel={handleCancelCloseApplication}/>}
            <Dialog
                fullScreen={isMobile}
                maxWidth='xl'
                open={openApplication}
                onClose={handleCloseApplication}
                aria-labelledby="responsive-dialog-title"
                TransitionComponent={Transition}
                keepMounted
                style={styles.dialog}
            >
                    {loading && <Loading styleOverride={iframeOverrideStyle}/>}
                    <iframe 
                        style={iframeStyle}
                        title='uown' 
                        id='application-uown' 
                        src={fullIframeUrl}
                        onLoad={iframeLoaded}
                    />
            </Dialog>
        </div>
    )
};

export default Radium(ApprovalUown);