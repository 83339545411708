import { useEffect, useContext } from 'react';
import { AppContext } from '../context';
import { sendHitStreetHSID } from '../utilities/middleware';
import { flattenString, formatPhone, getCookie } from '../utilities/helpers';
import { useHistory } from 'react-router-dom';
import { ADD_USER_OPTIN } from '../utilities/mutations';
import { useMutation } from '@apollo/client';

const useSetNewUser = () => {
    let history = useHistory();
    const myURL = new URL(window.location.href);
    const { dispatchTracking, dispatchApp, GtagEvent } = useContext(AppContext);
	
    const [addUserOptin] = useMutation(ADD_USER_OPTIN, { onError: (error) => { console.error('Saving User: FAILED', error); } });
    
    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const checkForUserParams = (hsid) => {
        const fname = myURL.searchParams.get('fname') || null;
        const lname = myURL.searchParams.get('lname') || null;
        const email = myURL.searchParams.get('email') || null;
        const phone = myURL.searchParams.get('phone') || null;
        if(!!fname && !!lname && !!email && !! phone) {
            console.log('User data passed in params!');
            dispatchApp({ type: 'FORM_SUBMIT', payload: {
                first: fname,
                last: lname,
                email: email,
                phone: formatPhone(phone),
                clickId: Number(hsid)
            } });
            GtagEvent('Approvals', 'Form Sent', 'Contact Info', 'Open Acima Modal');
            addUserOptin({ variables: { visitor: {
                first: flattenString(fname),
                last: flattenString(lname),
                email: flattenString(email),
                phone: formatPhone(phone),
                clickId: Number(hsid)
            } } } );
        // Check for form flag
            const showForm = myURL.searchParams.get('form') || null;
            if(showForm === '1') {
                history.push('/');
            } else {
                history.push('/acima');
            }
            window.scrollTo(0, 0);
        }
    }
    const buildNewUser = async () => {
        const tracking = {
            HSID: myURL.searchParams.get('hsid') || 0,
            PID: myURL.searchParams.get('pid') || 3143,
            SID: myURL.searchParams.get('sid') || 9189,
            OID: myURL.searchParams.get('oid') || 19,
            UID: myURL.searchParams.get('uid') || '',
            EID: myURL.searchParams.get('eid') || 'organic',
            SE: myURL.searchParams.get('se') || null,
            KWD: myURL.searchParams.get('kwd') || null,
            PACID: myURL.searchParams.get('pacid') || null,
            PT1: myURL.searchParams.get('pt1') || getCookie('pt1') || null,
            PT2: myURL.searchParams.get('pt2') || getCookie('pt2') || null,
            GCLID: myURL.searchParams.get('gclid') || ''
        };
        const clickId = await sendHitStreetHSID(tracking);
        const count = getRandomInt(1, 100);
        const payload = {
            hsid: clickId,
            pid: Number(tracking.PID),
            sid: Number(tracking.SID),
            oid: Number(tracking.OID),
            uid: tracking.UID,
            eid: tracking.EID,
            se: tracking.SE,
            kwd: tracking.KWD,
            pacid: tracking.PACID,
            pt1: tracking.PT1,
            pt2: tracking.PT2,
            gclid: tracking.GCLID,
            count: count
        };
        dispatchTracking({ type: 'USER_ARRIVED', payload });
        checkForUserParams(clickId);
    };

    
    useEffect(() => {
        buildNewUser();
        // eslint-disable-next-line
    }, []);

    return;
};

export default useSetNewUser;